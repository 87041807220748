<template>
	<!-- 禁止页面横向滚动 -->
	<div class="content" style="overflow-x: hidden;">
		<!-- 回到顶部功能 -->
		<goTop></goTop>
		<div class="banner-wrap">
			<el-image class="banner" fit="fill" :src="require('@/assets/img/banner_companyProduct.webp')" />
			<div class="banner-text-wrap">
				<beatText text="数字化转型专家" fontSize="50px" fontWeight="bold" />
				<beatText text="联接发展要素/深度整合资源/发挥数据价值" fontSize="30px" />
			</div>
		</div>
		<div class="title">
			<h2>解决方案</h2>
			<p>solutions</p>
		</div>

		<div class="list-wrap">
			<div class="list-item" v-for="(item, index) in list" :key="index"
				:style="{ marginTop: index == 0 ? '50px' : '150px' }" :ref="'listItem' + index">
				<el-image class="item-image" fit="fill" :src="item.imagePath" :style="{
            transform: index % 2 != 0 ? 'translateX(108%)' : 'translateX(0%)',
          }" />
				<div class="item-info" :style="{
            transform: index % 2 != 0 ? 'translateX(-93%)' : 'translateX(0%)',
            height: itemHeight + 'px',
          }">
					<div class="item-title">
						{{ item.title }}
					</div>
					<div class="item-text">
						{{ item.text }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// banner文字
	import beatText from "@/components/beat-text";
	// 回到顶部
	import goTop from '@/components/goTop.vue'
	export default {
		components: {
			beatText,
			goTop
		},
		data() {
			return {
				list: [{
						title: "境外流入性出入境疫情防控",
						text: "在全国疫情防控总体形势持续向好,但境外疫情形势严峻的背景下，依托浙江边检全方位精准数据支持，结合中正数服在行业内积累和优势，开展数智云判综合态势感知服务，进一步助力构建起多层次、全链条、立体化的防控体系，内防输出、外防输入，坚决遏制疫情通过口岸传播扩散，保障出入境旅客安全。",
						imagePath: require("@/assets/img/image_jwlryqpt.png"),
					},
					{
						title: "境外涉毒涉诈大数据研判",
						text: "境外涉毒涉诈违法犯罪日益猖獗，为打击治理涉毒涉诈非法出入境犯罪，利用大数据态势感知对潜在入境犯罪风险进行研判，及时有效采取专项行动，极大震慑违法犯罪分子，最大限度压缩犯罪空间，切实维护社会公民财产安全和合法权益。",

						imagePath: require("@/assets/img/image_jwsdqzpt.png"),
					},
					{
						title: "沿海船舶实时管控大数据研判",
						text: "随着大数据时代的到来，船舶实时管控需求激增，中正数服依托外部数据支持将大数据存储管理、大数据空间分析和大数据实时流处理等技术融合，形成了全新的船舶实时管控大数据研判技术体系，解决了用户一直以来对于海量数据的存储和可视化的问题，节约了用户数据存储和挖掘的成本，实现实时检索直观的监控沿海船舶的位置分布和轨迹追踪。",

						imagePath: require("@/assets/img/image_yhcbpt.png"),
					},
					{
						title: "社会治理大数据研判预警",
						text: "在全球疫情和百年未有之大变局背景下，利用大数据推进国家治理体系和治理能力现代化成为关键一环，中正数服响应国家政策结合社会需求，把移动互联网、物联网及云计算、大数据、人工智能技术与社会治理深度融合，为提高社会治理社会化、法治化、智能化、专业化，研判预警各类风险能力，为推进社会治理创新、努力建设更高水平的平安中国，实现企业社会职能，彰显企业社会责任与价值。",

						imagePath: require("@/assets/img/image_shzlpt.png"),
					},
					{
						title: "大型安保活动数据研判预警",
						text: " 随着现代科技的发展，大型安保活动数据研判预警需要社会大数据提供技术支撑，利用大数据态势感知平台进行数据研判预警，提升大型安保活动数据研判预警的监测能力，发挥优势，占领信息战役制高点；对信息进行分析、对比、推理、判断，提供精准预警，使得有关部门有针对性地开展演练，大大提高大型活动的预警和处突能力。",
						imagePath: require("@/assets/img/dxabhdpt.jpg"),
					},
				],
				itemHeight: 0,
			};
		},
		mounted() {
			let that = this;
			setTimeout(() => {
				that.itemHeight = that.$refs.listItem0[0].offsetHeight * 0.9;
			}, 300);
		},
		methods: {},
	};
</script>
<style lang="scss" scoped>
	.title {
		width: 100%;
		position: relative;
		height: 63px;
		margin-top: 63px;

		h2 {
			height: 35px;
			font-size: 36px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #333333;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 100;
		}

		p {
			height: 28px;
			font-size: 36px;
			font-family: Verdana;
			font-weight: 400;
			color: #F3F3F3;
			position: absolute;
			top: 60%;
			left: 50%;
			transform: translateX(-50%);
			text-transform: uppercase;
		}
	}


	.content {
		width: 100%;
		display: flex;
		flex-flow: column;
		align-items: center;

		.banner-wrap {
			position: relative;

			.banner {
				width: 100%;
				height: 500px;
			}

			.banner-text-wrap {
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				transform: translate(-50%, -50%);

				.banner-title {
					font-size: 54px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #ffffff;
				}

				.banner-text {
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: 300;
					color: #ffffff;
				}
			}
		}

		.list-wrap {
			width: 80%;
			height: fit-content;
			padding-bottom: 150px;

			.list-item {
				width: 100%;
				height: fit-content;

				display: flex;
				justify-content: space-between;
				align-items: center;

				.item-image {
					width: 48%;
				}

				.item-info {
					width: 52%;
					height: 80%;
					padding: 50px;
					box-sizing: border-box;
					display: flex;
					flex-flow: column;
					justify-content: center;
					background: #f3f3f3;

					.item-title {
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #2d2d2d;
					}

					.item-text {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
						margin-top: 34px;
						line-height: 36px;
					}
				}
			}
		}
	}
</style>
